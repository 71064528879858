
import { defineComponent } from "vue"
import { Product } from "@/types"
export default defineComponent({
  name: "PublicCard",
  components: {},
  props: {
    product: {
      type: Object as () => Product,
      required: true,
    },
  },
})
