
import { computed, defineComponent, onMounted } from "vue"
import PublicHeader from "@/components/public/PublicHeader.vue"
import PublicFooter from "@/components/public/PublicFooter.vue"
import PublicCard from "@/components/public/PublicCard.vue"
import { getAndSetProducts, productsState } from "@/composables/useProducts"

// @ is an alias to /src

export default defineComponent({
  name: "ProductList",

  components: {
    PublicCard,
    PublicHeader,
    PublicFooter,
  },

  setup() {
    onMounted(() => {
      getAndSetProducts()
    })

    return {
      products: computed(() => productsState.products),
      loading: computed(() => productsState.loading),
    }
  },
})
